<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>¡Bienvenido a CréditoPlus</h1>
        <h1>Tu Solución Financiera</h1>
        <h1>Instantánea!</h1>
        <!-- <h2>Your trusted lending platform in Nigeria. </h2> -->
        <!-- <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="inner inner2">
      <!-- <h1>Why choose CréditoPlus? </h1> -->
      <div class="content flex fw-w jc-sb">
        <div v-for="( item,index ) in inner_2item" :key="index" :style="{width:'48%'}" class="">
          <img :src="item.img">
          <h3>{{ item.title }}</h3>
          <p>{{ item.txt }}</p>
          <p v-if="item.txt2">{{ item.txt2 }}</p>
          <p v-if="item.txt3">{{ item.txt3 }}</p>
        </div>
      </div>
    </div>
    <div class="inner inner3 flex ai-c jc-sb">
      <div>
        <h1>Comienza Tu Viaje Financierod</h1>
        <p>Explora nuestro sitio y descubre lo fácil que puede ser obtener el respaldo
          financiero que necesitas. Con CréditoPlus, estarás un paso más cerca de
          tus metas.</p>
      </div>
      <img src="@/assets/images/loanImg/inner3_phone.png" alt="">
      <div class="item_download" @click="downloadApp()">
        <img src="@/assets/images/loanImg/Google-play.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_2item: [
        { title: 'Obtén Efectivo al Instante \n Vcon CréditoPlus',
          txt: 'En CréditoPlus entendemos que la vida está llena de sorpresas.\n Por eso, te ofrecemos acceso rápido y sencillo a préstamos en \n efectivo cuando más los necesitas. ¡Tu solución financiera está \n a solo unos clics de distancia!',
          img: require('@/assets/images/icon-4.png')
        },
        { title: 'Ventajas que te \n Ofrecemos',
          txt: 'Préstamos Rápidos: Obtén tu dinero en minutos.',
          txt2: 'Proceso Fácil: Completa nuestro sencillo formulario en línea en pocos pasos.',
          txt3: 'Sin Papeleo: Olvídate de trámites complicados y largas esperas.',
          img: require('@/assets/images/icon-5.png')
        }
        // { title: 'Seguridad y Privacidad',
        //   txt: 'Seguridad garantizada: Tus datos están \n protegidos con las más altas medidas \n de seguridad.',
        //   txt2: 'Privacidad respetada: Nos comprometemos \n a proteger tu información personal.',
        //   img: require('@/assets/images/icon-6.png')
        // },
        // { title: 'Atención al Cliente',
        //   txt: 'Atención personalizada: Nuestro equipo \n está aquí para ayudarte en cada paso.',
        //   txt2: 'Preguntas frecuentes: Encuentra respuestas \n a las dudas más comunes.',
        //   img: require('@/assets/images/icon-7.png')
        // }
      ],
      inner_4items: [
        // 您的轮播内容数据
        { id: '1', name: 'Lando Wolf', txt: 'Descarga la App', txt2: 'Download and Install the App: Visit our website \n or your device’s app store to download and install \n CréditoPlus on your mobile phone. ', img: require('@/assets/images/icon-1.png') },
        { id: '2', name: 'Alice Ura', txt: 'Registrarse y verificar', txt2: 'Sign Up: Open the app and sign up for an account \n using your mobile number. We will send you a \n verification code to ensure the security of your \n account.', img: require('@/assets/images/icon-2.png') },
        { id: '3', name: 'Marino Zavaleta', txt: 'Completar la solicitud', txt2: 'Apply for a Loan: Once you have successfully \n signed up, you can apply for a loan by selecting \n the loan amount and the repayment duration that \n suits your needs. Our loan application process is \n quick and straightforward, with minimal \n requirements.', img: require('@/assets/images/icon-3.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=credito.plus.dinero')
    }
  }
}
</script>

<style lang="scss" scoped>
  .home_top{
    min-width: 1200px;
    font-family: Arial, Arial;
    background: #fff;
    .inner{
      width:100vw;
      // padding:50px calc((100% - 1100px) / 2) 50px;
      margin: 0 auto;
      max-width: 1500px;
      padding: 0 12.5rem;
    }
    .bottom-20{
      margin-bottom: 20px;
    }
    .inner1{
      // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
      background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
      background-size: 100% auto;
      background-position-x: center;
      height: 31rem;
      display: flex;
      align-items: center;
      color: black;
      padding: 0 12.5rem;
      .left_item{
        float: left;
        width: 50%;
        background: #fff;
        opacity: 0.7;
        padding: 30px 25px;
        border-radius: 10px;
        h1{
          font-size: 35px;
          // color: white;
          font-weight: 700;
          margin-bottom:20px;
        }
        h2{
          padding-right: 17.5px;
          font-size: 30px;
          line-height: 26px;
          // margin-bottom: 10px;
        }
        // span{
        //   color: #FF5833;
        // }
        .item_progress{
          width: 230px;
          p{
            width: 90px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            color: white;
            font-size: 12px;
            font-weight: bold;
            margin: 0 auto 10px;
            background: #4488EB;
          }
          img{
            width: 245px;
            height: 15px;
          }
        }
        .item_info{
          margin: 20px 0;
          display: flex;
          height: 100px;
          .info_img1{
            width: 15px;
          }
          .info_tips{
            display: flex;
            flex-direction: column;
            p{
              flex: 1;
              display: flex;
              box-sizing: border-box;
              // margin-left: 10px;
              font-size: 12px;
              color: #666666;
              align-items: center;
              img{
                margin-right: 10px;
              }
            }
          }
          .info_img2{
            width: 135px;
            height: 135px;
          }
        }
      }
      .right_item{
        float: right;
        // margin-right: 50px;
        img{
          width: 550px;
        }
      }
    }
    .inner2{
      margin: 80px auto;
      .content{
        div{
          padding: 280px 55px 30px;
          // background: #F5F7F4;
          border: 1.5px solid #6A25AD;
          text-align: center;
          font-size: 18px;
          line-height: 24px;
          border-radius: 15px;
          position: relative;
          h3{
            line-height: 30px;
            padding: 0 40px;
            margin-bottom: 20px;
            white-space: pre-line;
          }
          p{
            text-align: left;
            font-size: 12px;
            color: #666666;
          }
          img{
            top: -2px;
            left: -1.5px;
            position: absolute;
            width: calc(100% + 3px);
          }
        }
      }
    }
    .inner3{
      font-family: Arial, Arial;
      width: 100%;
      background: #5808A5;
      color: white;
      line-height: 25px;
      h1{
        line-height: 60px;
      }
      .item_download{
        display: flex;
        width: 200px;
        // background: black;
        color: white;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        img{
          width: 200px;
          height: 60px;
        }
      }
      img{
        width: 150px;
        margin: 0 80px;
      }
    }
  }
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
      width: 100vw;
    }
    .inner1{
      height: 130px;
      position: relative;
      padding: 0 20px;
      h1{
        font-size: 14px !important;
        line-height: 20px !important;
        margin-bottom: 0 !important;
      }
      h2{
        font-size: 12px !important;
      }
      .left_item{
        background: transparent;
        width: 100%;
        padding: 0 25px;
        color: white;
        .item_download{
          margin-top: 20px;
          justify-content:start;
          img{
            width: 150px;
            height: 50px;
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .inner2{
      padding: 0;
      h1{
        font-size: 25px;
        width: 100%;
        text-align: center;
        margin-bottom: 0;
      }
      .content{
        padding: 0;
        justify-content: center !important;
        div{
          width: 90% !important;
          padding: 190px 20px 20px;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
    .inner3{
      padding: 20px 20px !important;
      justify-content: center !important;
      h1{
        font-size: 20px;
        line-height: 40px;
      }
      h2{
        text-align: center;
      }
      img{
        margin: 20px 0 ;
      }
    }
  }
}
</style>
