<template>
  <div class="hotel-home">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <HomeTop />
      <!-- <HomeBottom /> -->
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import HomeTop from '@/components/home/homeTop'
// import HomeBottom from '@/components/home/homeBottom'
export default {
  components: {
    NavTab,
    HomeTop,
    // HomeBottom,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 0,
        list: [
          {
            name: 'Página de Inicio',
            link: '/'
          },
          {
            name: 'Sobre Nosotros',
            link: '/sobre_nosotros'
          },
          {
            name: 'Preguntas Frecuentes',
            link: '/preguntas_frecuentes'
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.con_center{
  // background: url("~@/assets/images/loanImg/bn_ee.png") no-repeat top left;
  // margin-top: 70px;
  // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
}
</style>
